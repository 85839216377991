import { FC, useState } from "react";
import BaseColor from "../../consts/base-colors";
import { cn } from "@bem-react/classname";
import "./color-input-component.scss";

type ColorInputProps = {
  color: BaseColor;
  index: number;
  selectIndex: number;
  title: string;
  OnChange: (index: number, value: string) => void;
  SetSelectIndex: (index: number) => void;
};

const cat = cn("color");
const ColorInput: FC<ColorInputProps> = ({
  color,
  index,
  selectIndex,
  OnChange,
  SetSelectIndex,
  title,
}) => {
  return (
    <div
      className={cat()}
      key={color.name}
      title={title}
      style={
        index == selectIndex
          ? {
              boxShadow: "black 0px 0px 8px 0px inset",
              borderColor: "#" + color.color,
            }
          : { borderColor: "#" + color.color }
      }
    >
      <span title={title}>{color.name}</span>
      <div>
        <input
          title={title}
          value={color.color}
          onChange={(e) => OnChange(index, e.target.value)}
          onClick={() => SetSelectIndex(index)}
        />
      </div>
    </div>
  );
};

export default ColorInput;
