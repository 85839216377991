import { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import "./modifiers-list-component.scss";
import Modifier from "../../../consts/modifier";
import BaseColor from "../../../consts/base-colors";
import BaseFont from "../../../consts/base-font";

type ModifiersListProps = {
  modifies: Modifier[];
  colors: BaseColor[];
  fonts: BaseFont[];
  selectIndex: number;
};

const cat = cn("list");
const ModifiersList: FC<ModifiersListProps> = ({
  modifies,
  colors,
  fonts,
  selectIndex,
}) => {
  return (
    <div className={cat()}>
      <p
        className={selectIndex == 4 ? "backlighttext" : ""}
        style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
      >
        Максимум 2, минимум 1
      </p>
      {modifies.map((c) => {
        return (
          <div
            key={c.id}
            className={"modif " + (selectIndex == 3 ? "backlight" : "")}
            style={{ borderColor: "#" + colors[3].color }}
          >
            <p
              className={selectIndex == 4 ? "backlighttext" : ""}
              style={{
                fontFamily: fonts[0].name,
                color: "#" + colors[4].color,
              }}
            >
              {c.name}
            </p>
            <div>
              <span
                className={selectIndex == 4 ? "backlighttext" : ""}
                style={{
                  fontFamily: fonts[0].name,
                  color: "#" + colors[4].color,
                }}
              >
                {c.price} ₽
              </span>
              <div>
                {" "}
                <span
                  className={selectIndex == 4 ? "backlighttext" : ""}
                  style={{
                    fontFamily: fonts[0].name,
                    color: "#" + colors[4].color,
                  }}
                >
                  -
                </span>
                <span
                  className={selectIndex == 4 ? "backlighttext" : ""}
                  style={{
                    fontFamily: fonts[0].name,
                    color: "#" + colors[4].color,
                  }}
                >
                  1
                </span>
                <span
                  className={selectIndex == 4 ? "backlighttext" : ""}
                  style={{
                    fontFamily: fonts[0].name,
                    color: "#" + colors[4].color,
                  }}
                >
                  +
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ModifiersList;
