import { cn } from "@bem-react/classname";
import "./list-orders-component.scss";
import { FC } from "react";
import BaseColor from "../../../consts/base-colors";
import Order from "../../../consts/order";
import BaseFont from "../../../consts/base-font";
import CategoriesGroup from "../../template-ansoftbase/categories-group-component/categories-group-component";
import OrdersGroup from "../../template-ansoftbase/orders-group-component/orders-group-component";

const cat = cn("list-order-horizontal");
const categories = [
  "Пицца",
  "Бургеры",
  "Салаты",
  "Супы",
  "Соусы",
  "Десерты",
  "Напитки",
];
const orders = [
  new Order(0, "Воппер Сибирский", 280),
  new Order(1, "Сибирский Чикен", 280),
  new Order(2, "Чикен Ролл", 280),
  new Order(3, "Шримп Ролл", 280),
  new Order(4, "Капучино", 280),
  new Order(5, "А4 Бокс", 280),
  new Order(6, "Картофель фри", 280),
  new Order(7, "Соус Сырный", 280),
  new Order(8, "Соус Томатный", 280),
  new Order(9, "Соус Цезарь", 280),
  new Order(10, "Соус Карри", 280),
];
type ListOrderProps = {
  colors: BaseColor[];
  fonts: BaseFont[];
  id?: string;
  img?: any;
  stub?: any;
  selectIndex: number;
};
const ListOrdersComponent: FC<ListOrderProps> = ({
  colors,
  fonts,
  img,
  selectIndex,
  stub,
  id,
}) => {
  return (
    <div
      className={cat("", [selectIndex == 0 ? "backlight" : ""])}
      id={id}
      style={{ backgroundColor: "#" + colors[0].color }}
    >
      <img src={img} />
      <div className={cat("bottom")}>
        <button
          className={
            selectIndex == 10
              ? "backlight"
              : selectIndex == 11
              ? "backlighttext"
              : ""
          }
          style={{
            fontFamily: fonts[0].name,
            backgroundColor: "#" + colors[10].color,
            color: "#" + colors[11].color,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z"></path>
          </svg>
          <span
            style={{
              fontFamily: fonts[0].name,
              color: "#" + colors[7].color,
            }}
            className={selectIndex == 7 ? "backlighttext" : ""}
          >
            Удалить
          </span>
        </button>
        <button
          className={
            selectIndex == 10
              ? "backlight"
              : selectIndex == 11
              ? "backlighttext"
              : ""
          }
          style={{
            fontFamily: fonts[0].name,
            backgroundColor: "#" + colors[10].color,
            color: "#" + colors[11].color,
          }}
        >
          <span
            style={{
              fontFamily: fonts[0].name,
              color: "#" + colors[7].color,
            }}
            className={selectIndex == 7 ? "backlighttext" : ""}
          >
            Ваш заказ
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z" />
          </svg>
        </button>
      </div>
      <CategoriesGroup
        selectIndex={selectIndex}
        categories={categories}
        colors={colors}
        fonts={fonts}
      />
      <OrdersGroup
        stub={stub}
        selectIndex={selectIndex}
        orders={orders}
        colors={colors}
        fonts={fonts}
      />
    </div>
  );
};
export default ListOrdersComponent;
