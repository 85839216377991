import pdfMake from "pdfmake/build/pdfmake";
import { Buffer } from "buffer";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const url = "";
window.Buffer = Buffer;
pdfMake.fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
  },
};
function blobToBase64(blob: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // Получаем результат и убираем префикс
      reader &&
        reader.result &&
        resolve((reader.result as string).split(",")[1]); // Возвращаем только часть Base64
    };

    reader.onerror = () => {
      reject(new Error("Failed to convert Blob to Base64"));
    };

    reader.readAsDataURL(blob); // Читаем Blob как Data URL
  });
}
async function CreateImage() {
  var htmlToImage = require("html-to-image");
  let promisesImages: any[] = [];
  const styleSheet = document.styleSheets[0];
  const ruleIndexback = styleSheet.insertRule(
    ".backlight{ box-shadow: none !important; }",
    styleSheet.cssRules.length
  );
  const ruleIndextext = styleSheet.insertRule(
    ".backlighttext { text-shadow: inherit!important;}",
    styleSheet.cssRules.length
  );
  const ruleIndexcard = styleSheet.insertRule(
    ".card-order{box-shadow: -1px 2px 3px #3a3a3a!important;}",
    styleSheet.cssRules.length
  );
  promisesImages.push(
    htmlToImage.toBlob(document.getElementById("ListOrdersComponent"))
  );
  promisesImages.push(
    htmlToImage.toBlob(document.getElementById("ListOrdersWithBasketComponent"))
  );
  promisesImages.push(
    htmlToImage.toBlob(document.getElementById("OrderComponent"))
  );
  promisesImages.push(
    htmlToImage.toBlob(document.getElementById("OrderResultComponent"))
  );
  promisesImages.push(
    htmlToImage.toBlob(document.getElementById("ListOrdeWithModalComponent"))
  );

  let images: any = await Promise.all(promisesImages);
  styleSheet.deleteRule(ruleIndexcard);
  styleSheet.deleteRule(ruleIndextext);
  styleSheet.deleteRule(ruleIndexback);
  let base64 = images.map((i: any) => {
    return blobToBase64(i);
  });
  let res: any = await Promise.all(base64);
  return res;
}

async function CreateColorSheet(file: any, name: string = "document.pdf") {
  let blob = new Blob([file]);
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

async function ZipFiles(files: any[], names: string[]) {
  const zip = new JSZip();
  files.map((f, i) => {
    zip.file(names[i], new Blob([f]));
  });

  const content = await zip.generateAsync({ type: "blob" });

  saveAs(content, "AnSoft.zip");
}
export async function DonloadJson(form: any) {
  saveAs(
    new Blob([JSON.stringify(form, null, 2)], {
      type: "application/json;charset=" + document.characterSet,
    }),
    "asoft.json"
  );
}

export async function DonloadForm(form: any) {
  let xmlBlob: any = await axios.post(
    url + "/api/settings",
    { form },
    { responseType: "blob" }
  );
  let file: any = await axios.post(
    url + "/api/form",
    { form },
    { responseType: "blob" }
  );
  let json = new Blob([JSON.stringify(form, null, 2)], {
    type: "application/json;charset=" + document.characterSet,
  });

  ZipFiles(
    [xmlBlob.data, file.data, json],
    ["Settings.config", "Чек-лист AnSoft.pdf", "asoft.json"]
  );
}

export async function DonloadFiles(
  colors: any,
  fonts: any,
  nameClient: any,
  license: any,
  icons: any,
  template:string
) {
  let images = await CreateImage();
  let colorsBlob: any = await axios.post(
    url + "/api/colors",
    { colors, fonts },
    { responseType: "blob" }
  );
  let brifBlob: any = await axios.post(
    url + "/api/brif",
    {
      colors,
      fonts,
      images,
      nameClient,
      license,
    },
    { responseType: "blob" }
  );
  ZipFiles(
    [brifBlob.data, colorsBlob.data, fonts[0].file, ...icons],
    [
      "Бриф AnSoft " + nameClient + ".pdf",
      template+"_Colors.xaml",
      fonts[0].name + ".ttf",
      ...icons.map((e: any) => (e ? e.name : "")),
    ]
  );
}
