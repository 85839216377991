import { FC, useEffect, useState } from "react";
import "./select-component.scss";
import Select from "react-select";
import { valueContainerCSS } from "react-select/dist/declarations/src/components/containers";

type SelectProps = {
  placeholder: string;
  options: string[];
  isMulti?: boolean;
  isError?: boolean;
  isRequired?: boolean;
  value: string[];
  helper?: string;
  isClearable?:boolean;
  onChange: (value: any) => void;
};

const SelectForm: FC<SelectProps> = ({
  placeholder,
  value,
  isError,
  isClearable = true,
  isRequired,
  options,
  isMulti,
  helper,
  onChange,
}) => {
  const [list, setList] = useState(
    options.map((e) => {
      return { value: e, label: e };
    })
  );
  return (
    <div title={helper} className="select">
      <p className="placeholder" title={helper}>
        {placeholder}
      </p>
      <Select
        className={isError ? "select error" : "select"}
        isClearable = {isClearable}
        value={list.filter((e) => value.includes(e.value))}
        isMulti={isMulti}
        placeholder={
          <span>
            {" "}
            {placeholder}
            <span className="required">{isRequired == true ? " *" : ""}</span>
          </span>
        }
        options={list}
        onChange={(e: any) => {
          if (Array.isArray(e)) {
            e && onChange(e.map((c) => c?.value).join(","));
          } else {
            onChange(e?.value);
          }
        }}
      />
      {/* <p className="helper">{helper}</p> */}
    </div>
  );
};

export default SelectForm;
