import { cn } from "@bem-react/classname";
import "./order-result-component.scss";
import { FC } from "react";
import { fonts } from "pdfmake/build/pdfmake";
import BaseColor from "../../../consts/base-colors";
import BaseFont from "../../../consts/base-font";
import ResultItemComponent from "../result-item-component/result-item-component";

const img = require("./../../../shared/imges/orderImg.png");
const cat = cn("order_result-vertical");
type ListOrderProps = {
  colors: BaseColor[];
  image: any;
  fonts: BaseFont[];
  selectIndex: number;
};
const OrderResultComponent: FC<ListOrderProps> = ({
  colors,
  fonts,
  image,
  selectIndex,
}) => {
  return (
    <div
      className={cat("", [selectIndex == 0 ? "backlight" : ""])}
      id="OrderResultComponent"
      style={{ backgroundColor: "#" + colors[0].color }}
    >
      <img src={image}></img>
      <div className={cat("top")}>
        <p
          className={selectIndex == 4 ? "backlighttext" : ""}
          style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
        >
          Ваш заказ
        </p>
        <div
          className={selectIndex == 4 ? "backlighttext" : ""}
          style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
          </svg>
          Назад
        </div>
      </div>

      <div className={cat("goods")}>
        <ResultItemComponent
          selectIndex={selectIndex}
          fonts={fonts}
          colors={colors}
        />
        <ResultItemComponent
          selectIndex={selectIndex}
          fonts={fonts}
          colors={colors}
        />
        <ResultItemComponent
          selectIndex={selectIndex}
          fonts={fonts}
          colors={colors}
        />
      </div>
      <div className={cat("res")}>
        <p
          className={selectIndex == 4 ? "backlighttext" : ""}
          style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
        >
          3 позиции
        </p>
        <p
          className={selectIndex == 4 ? "backlighttext" : ""}
          style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
        >
          Всего к оплате:
        </p>
        <p
          className={selectIndex == 4 ? "backlighttext" : ""}
          style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
        >
          1024 ₽
        </p>
      </div>

      <div className={cat("buttons")}>
        <button
          className={"back " + (selectIndex == 10 ? "backlight" : "")}
          style={{
            fontFamily: fonts[0].name,
            color: "#" + colors[10].color,
          }}
        >
          Назад
        </button>
        <button
          className={
            selectIndex == 10
              ? "backlight"
              : selectIndex == 11
              ? "backlighttext"
              : ""
          }
          style={{
            fontFamily: fonts[0].name,
            backgroundColor: "#" + colors[10].color,
            color: "#" + colors[11].color,
          }}
        >
          Оплатить
        </button>
      </div>
    </div>
  );
};
export default OrderResultComponent;
