// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-order {
  display: grid;
  grid-template-rows: 1fr;
  height: 800px;
}
.list-order > div {
  display: flex;
  padding-left: 20px;
}
.list-order > div:first-child {
  padding: 30px 10px;
  column-gap: 10px;
}
.list-order > img {
  width: 100%;
  height: 100px;
}
.list-order-bottom {
  height: 100px;
  flex-direction: column;
}
.list-order-bottom .top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 26px;
  padding-left: 5px;
  height: 50px;
  box-sizing: border-box;
}
.list-order-bottom .top svg:first-child {
  transform: rotate(180deg);
}
.list-order-bottom .top > div {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.list-order-bottom .buttons {
  display: flex;
  justify-content: space-around;
  padding: 0px 15px;
  padding-left: 0px;
}
.list-order-bottom .buttons button {
  height: 30px;
  width: 110px;
  border: 1px;
  border-radius: 5px;
}
.list-order-bottom .buttons .pay_button {
  width: 140px;
}`, "",{"version":3,"sources":["webpack://./src/components/template-ansoftbase/list-orders-component/list-orders-component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EAEA,aAAA;AAAF;AACE;EACE,aAAA;EACA,kBAAA;AACJ;AACE;EACE,kBAAA;EACA,gBAAA;AACJ;AACE;EACE,WAAA;EACA,aAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;AAAJ;AACI;EACE,aAAA;EACA,8BAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,sBAAA;AACN;AAAM;EACE,yBAAA;AAER;AAAM;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAER;AACI;EACE,aAAA;EAEA,6BAAA;EACA,iBAAA;EACA,iBAAA;AAAN;AACM;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AACR;AACM;EACE,YAAA;AACR","sourcesContent":[".list-order {\n  display: grid;\n  grid-template-rows: 1fr;\n\n  height: 800px;\n  > div {\n    display: flex;\n    padding-left: 20px;\n  }\n  > div:first-child {\n    padding: 30px 10px;\n    column-gap: 10px;\n  }\n  > img {\n    width: 100%;\n    height: 100px;\n  }\n\n  &-bottom {\n    height: 100px;\n    flex-direction: column;\n    .top {\n      display: flex;\n      justify-content: space-between;\n      width: 100%;\n      padding: 10px 26px;\n      padding-left: 5px;\n      height: 50px;\n      box-sizing: border-box;\n      svg:first-child {\n        transform: rotate(180deg);\n      }\n      > div {\n        display: flex;\n        align-items: center;\n        column-gap: 5px;\n      }\n    }\n    .buttons {\n      display: flex;\n      \n      justify-content: space-around;\n      padding: 0px 15px;\n      padding-left: 0px;\n      button {\n        height: 30px;\n        width: 110px;\n        border: 1px;\n        border-radius: 5px;\n      }\n      .pay_button {\n        width: 140px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
