// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  max-width: calc(100vw - 430px);
  min-width: min-content;
  height: 800px;
  display: flex;
  flex: 1 1;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  justify-content: center;
  padding: 5px;
  overflow-y: auto;
  background: #bfbfbf;
  border: 1px;
  border-radius: 10px;
}
.page > .item {
  box-shadow: 2px 5px 6px grey;
  min-width: 450px;
  max-width: 450px;
  width: 450px;
  height: 800px;
}
.page > .item > img {
  width: 100%;
  height: 100px;
}
.page .bottom_screen {
  cursor: pointer;
  position: sticky;
  width: 100%;
  height: 0;
  z-index: 5;
  top: 0;
}
.page .bottom_screen > button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 25px;
  display: inline-block;
  transition: background-color 0.3s;
}
.page .bottom_screen > button > span {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/pages-component/ansoftbase-page-component/ansoftbase-page-component.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AACF;AAAE;EACE,4BAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;AAEJ;AADI;EACE,WAAA;EACA,aAAA;AAGN;AACE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,MAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,eAAA;EACA,qBAAA;EACA,iCAAA;AACN;AAAM;EACE,iBAAA;AAER","sourcesContent":[".page {\n  max-width: calc(100vw - 430px);\n  min-width: min-content;\n  height: 800px;\n  display: flex;\n  flex: 1;\n  column-gap: 10px;\n  flex-wrap: wrap;\n  row-gap: 10px;\n  justify-content: center;\n  padding: 5px;\n  overflow-y: auto;\n  background: #bfbfbf;\n  border: 1px;\n  border-radius: 10px;\n  > .item {\n    box-shadow: 2px 5px 6px grey;\n    min-width: 450px;\n    max-width: 450px;\n    width: 450px;\n    height: 800px;\n    > img {\n      width: 100%;\n      height: 100px;\n    }\n  }\n\n  .bottom_screen {\n    cursor: pointer;\n    position: sticky;\n    width: 100%;\n    height: 0;\n    z-index: 5;\n    top: 0;\n\n    > button {\n      width: 40px;\n      height: 40px;\n      border-radius: 50%;\n      border: none;\n      background-color: #007bff;\n      color: white;\n      font-size: 25px;\n      display: inline-block;\n      transition: background-color 0.3s;\n      > span {\n        font-weight: bold;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
