import { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import "./mini-card-order-component.scss";
import BaseColor from "../../../consts/base-colors";
import Order from "../../../consts/order";
const cat = cn("mini-card-order");
const logo = require("./../../../shared/imges/orderImg.png");

type CardOrderProps = {
  colors: BaseColor[];
  order: Order;
  selectIndex: number;
};
const MiniCardOrderComponent: FC<CardOrderProps> = ({
  colors,
  order,
  selectIndex,
}) => {
  return (
    <div className={cat("")}>
      <div
        className={selectIndex == 1 ? "backlight" : ""}
      >
        <div>
          <p
            className={selectIndex == 2 ? "backlighttext" : ""}
            style={{ color: "#" + colors[2].color }}
          >
            {order.name}
          </p>
        </div>
        <div>
          <img src={logo} className={cat("img")}></img>
        </div>
        <div
          className={cat("count", [selectIndex == 8 ? "backlight" : ""])}
          style={{ backgroundColor: "#" + colors[8].color }}
        >
          <span
            className={selectIndex == 9 ? "backlighttext" : ""}
            style={{ color: "#" + colors[9].color }}
          >
            -
          </span>
          <span
            className={selectIndex == 9 ? "backlighttext" : ""}
            style={{ color: "#" + colors[9].color }}
          >
            1
          </span>
          <span
            className={selectIndex == 9 ? "backlighttext" : ""}
            style={{ color: "#" + colors[9].color }}
          >
            +
          </span>
        </div>
      </div>
      <button
        className={
          selectIndex == 10
            ? "backlight"
            : selectIndex == 11
            ? "backlighttext"
            : ""
        }
        style={{
          backgroundColor: "#" + colors[10].color,
          color: "#" + colors[11].color,
        }}
      >
        Настроить
      </button>
    </div>
  );
};
export default MiniCardOrderComponent;
