// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-order-horizontal {
  display: grid;
  grid-template-rows: 100px 50px 60px auto;
  height: 800px;
}
.list-order-horizontal > div {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.list-order-horizontal > .orders {
  flex-direction: row;
}
.list-order-horizontal > div:first-child {
  padding: 30px 10px;
  column-gap: 10px;
}
.list-order-horizontal > img {
  width: 100%;
  height: 100px;
}
.list-order-horizontal .categories {
  overflow: hidden;
  width: 383px;
  flex-direction: row;
  margin-left: 10px;
  column-gap: 5px;
  row-gap: 5px;
}
.list-order-horizontal .categories > div {
  border-radius: 3px;
  width: 70px;
  height: 30px;
}
.list-order-horizontal-bottom {
  width: 400px;
  justify-content: unset;
  flex-wrap: wrap;
  justify-content: center;
  height: 60px;
  align-content: space-between;
}
.list-order-horizontal-bottom > button {
  border-radius: 3px;
  border: none;
  height: 33px;
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/template-horizontal/list-orders-component/list-orders-component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,wCAAA;EAEA,aAAA;AAAF;AACE;EACE,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AACE;EACE,mBAAA;AACJ;AACE;EACE,kBAAA;EACA,gBAAA;AACJ;AACE;EACE,WAAA;EACA,aAAA;AACJ;AAEE;EACE,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;AAAJ;AAEE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAAJ;AAGE;EACE,YAAA;EACA,sBAAA;EACA,eAAA;EACA,uBAAA;EACA,YAAA;EACA,4BAAA;AADJ;AAGI;EACE,kBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AADN","sourcesContent":[".list-order-horizontal {\n  display: grid;\n  grid-template-rows: 100px 50px 60px auto;\n\n  height: 800px;\n  > div {\n    flex-direction: column;\n    display: flex;\n    align-items: center;\n    padding-left: 20px;\n  }\n  > .orders {\n    flex-direction: row;\n  }\n  > div:first-child {\n    padding: 30px 10px;\n    column-gap: 10px;\n  }\n  > img {\n    width: 100%;\n    height: 100px;\n  }\n\n  .categories {\n    overflow: hidden;\n    width: 383px;\n    flex-direction: row;\n    margin-left: 10px;\n    column-gap: 5px;\n    row-gap: 5px;\n  }\n  .categories > div {\n    border-radius: 3px;\n    width: 70px;\n    height: 30px;\n  }\n\n  &-bottom {\n    width: 400px;\n    justify-content: unset;\n    flex-wrap: wrap;\n    justify-content: center;\n    height: 60px;\n    align-content: space-between;\n\n    > button {\n      border-radius: 3px;\n      border: none;\n      height: 33px;\n      width: 125px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      column-gap: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
