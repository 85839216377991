class BaseColor {
  id?: number;
  name?: string;
  color?: string;
  title?: string;
  screen?: number;
  constructor(
    id: number,
    name: string,
    color: string = "fff",
    title = "",
    screen = 0
  ) {
    this.id = id;
    this.name = name;
    this.color = color;
    this.title = title;
    this.screen = screen;
  }
}
export default BaseColor;
