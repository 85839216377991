import { cn } from "@bem-react/classname";
import "./order-result-component.scss";
import { FC } from "react";
import { fonts } from "pdfmake/build/pdfmake";
import BaseColor from "../../../consts/base-colors";
import BaseFont from "../../../consts/base-font";
import ResultItemComponent from "../result-item-component/result-item-component";

const img = require("./../../../shared/imges/orderImg.png");
const cat = cn("order_result");
type ListOrderProps = {
  colors: BaseColor[];
  image: any;
  fonts: BaseFont[];
  selectIndex: number;
};
const OrderResultComponent: FC<ListOrderProps> = ({
  colors,
  fonts,
  image,
  selectIndex,
}) => {
  return (
    <div
      className={cat("", [selectIndex == 0 ? "backlight" : ""])}
      id="OrderResultComponent"
      style={{ backgroundColor: "#" + colors[0].color }}
    >
      <img src={image}></img>
      <p
        className={selectIndex == 4 ? "backlighttext" : ""}
        style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
      >
        Проверьте заказ перед оплатой
      </p>
      <div className={cat("goods")}>
        <ResultItemComponent
          selectIndex={selectIndex}
          fonts={fonts}
          colors={colors}
        />
        <ResultItemComponent
          selectIndex={selectIndex}
          fonts={fonts}
          colors={colors}
        />
        <ResultItemComponent
          selectIndex={selectIndex}
          fonts={fonts}
          colors={colors}
        />
      </div>
      <div className={cat("auth")}>
        <p
          className={selectIndex == 4 ? "backlighttext" : ""}
          style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
        >
          Для авторизации в бонусной системе нажмите кнопку и введите
        </p>
        <button
          className={
            selectIndex == 10
              ? "backlight"
              : selectIndex == 11
              ? "backlighttext"
              : ""
          }
          style={{
            fontFamily: fonts[0].name,
            backgroundColor: "#" + colors[10].color,
            color: "#" + colors[11].color,
          }}
        >
          Авторизация
        </button>
      </div>
      <p
        className={selectIndex == 4 ? "backlighttext" : ""}
        style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
      >
        Сумма заказа: 1024 ₽
      </p>
      <div className={cat("buttons")}>
        <button
          className={"back " + (selectIndex == 10 ? "backlight" : "")}
          style={{
            fontFamily: fonts[0].name,
            borderColor: "#" + colors[10].color,
            color: "#" + colors[10].color,
          }}
        >
          Назад
        </button>
        <button
          className={
            selectIndex == 10
              ? "backlight"
              : selectIndex == 11
              ? "backlighttext"
              : ""
          }
          style={{
            fontFamily: fonts[0].name,
            backgroundColor: "#" + colors[10].color,
            color: "#" + colors[11].color,
          }}
        >
          Оплатить
        </button>
      </div>
    </div>
  );
};
export default OrderResultComponent;
