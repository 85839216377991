import "./App.css";
import { useEffect, useState } from "react";
import ColorPicker from "./components/color-picker-component/color-picker-component";
import BaseColor from "./consts/base-colors";
import BaseFont from "./consts/base-font";
import BlockForm from "./consts/block-form";
import axios from "axios";
import { Route, Routes } from "react-router-dom";
import Form from "./components/form-component/form-component";
import AnsoftbasePageComponent from "./components/pages-component/ansoftbase-page-component/ansoftbase-page-component";
import VerticalPageComponent from "./components/pages-component/vertical-page-component/vertical-page-component";
import HorizontalPageComponent from "./components/pages-component/horizontal-page-component/horizontal-page-component";

let base_colors = [
  new BaseColor(0, "Фон", "d2ddff", "На всех экранах", 0),
  new BaseColor(1, "Карточка товара", "e4e4ff", "Экран 1, Экран 2, Экран 5", 0),
  new BaseColor(
    2,
    "Текст карточки товара",
    "5981ba",
    "Экран 1, Экран 2, Экран 5",
    0
  ),
  new BaseColor(
    3,
    "Доп. элементы/рамочки",
    "03197c",
    "Экран 1, Экран 2, Экран 3, Экран 5",
    0
  ),
  new BaseColor(4, "Основной текст", "0c0c80", "На всех экранах", 0),
  new BaseColor(5, "Текст выбранного элемента", "ffffff", "Экран 3", 2),
  new BaseColor(6, "Доп. панели", "466abd", "Экран 1, Экран 2, Экран 5", 0),
  new BaseColor(
    7,
    "Текст доп. панели",
    "ffffff",
    "Экран 1, Экран 2, Экран 5",
    0
  ),
  new BaseColor(
    8,
    "Доп. некоторые элементы",
    "3234ad",
    "Экран 1, Экран 2, Экран 4, Экран 5",
    0
  ),
  new BaseColor(
    9,
    "Текст некоторых элементов",
    "ffffff",
    "Экран 1, Экран 2, Экран 4, Экран 5",
    0
  ),
  new BaseColor(
    10,
    "Кнопки подтверждения действий",
    "18107c",
    "На всех экранах"
  ),
  new BaseColor(11, "Текст кнопки", "ffffff", "На всех экранах"),
];

let base_fonts = [new BaseFont(0)];
const templates = ["MenuPageAnsoftBase", "Vertical","Horizontal"];
const App = () => {
  const [colors, setColors] = useState(base_colors);
  const [fonts, setFonts] = useState(base_fonts);
  const [selectIndex, setSelectIndex] = useState<number>(0);
  const [selectTemplate, setSelectTemplate] = useState<number>(0);
  const [screen, setScreen] = useState<number>(0);
  const [form, setForm] = useState<BlockForm[]>([]);
  const [banner, setBanner] = useState<any>("./top.png");
  const [stub, setStub] = useState<any>();

  const genTemplate = () => {
    switch (selectTemplate) {
      case 0: {
        return (
          <AnsoftbasePageComponent
            screen={screen}
            selectIndex={selectIndex}
            stub={stub}
            banner={banner}
            fonts={fonts}
            colors={colors}
          />
        );
        break;
      }
      case 1: {
        return (
          <VerticalPageComponent
            screen={screen}
            selectIndex={selectIndex}
            stub={stub}
            banner={banner}
            fonts={fonts}
            colors={colors}
          />
        );
        break;
      }
      case 2: {
        return (
          <HorizontalPageComponent
            screen={screen}
            selectIndex={selectIndex}
            stub={stub}
            banner={banner}
            fonts={fonts}
            colors={colors}
          />
        );
        break;
      }
    }
  };

  useEffect(() => {
    axios.get("/form.json").then((r) => {
      setForm(r.data);
    });
  }, [1]);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Form form={form} setForm={setForm} />} />
        <Route
          path="/template"
          element={
            <>
              <ColorPicker
                selectTemplate={selectTemplate}
                templates={templates}
                setTemplate={setSelectTemplate}
                setScreen={setScreen}
                setSelectIndex={setSelectIndex}
                selectIndex={selectIndex}
                fonts={fonts}
                colors={colors}
                setFonts={setFonts}
                setColors={setColors}
                setBanner={setBanner}
                setStub={setStub}
              />
              {genTemplate()}
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
