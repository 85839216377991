import { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import "./card-order-component.scss";
import BaseColor from "../../../consts/base-colors";
import BaseFont from "../../../consts/base-font";
import Order from "../../../consts/order";
const cat = cn("card-order");
const logo = require("./../../../shared/imges/orderImg.png");

type CardOrderProps = {
  colors: BaseColor[];
  order: Order;
  fonts: BaseFont[];
  image?: any;
  selectIndex: number;
};
const CardOrderComponent: FC<CardOrderProps> = ({
  colors,
  order,
  fonts,
  selectIndex,
  image,
}) => {
  return (
    <div
      className={cat("", [selectIndex == 1 ? "backlight" : ""])}
      style={{ backgroundColor: "#" + colors[1].color }}
    >
      <img src={image ? image : logo} className={cat("img")}></img>
      <p
        className={selectIndex == 2 ? "backlighttext" : ""}
        style={{ fontFamily: fonts[0].name, color: "#" + colors[2].color }}
      >
        {order.name}
      </p>
      <p
        className={selectIndex == 2 ? "backlighttext" : ""}
        style={{ fontFamily: fonts[0].name, color: "#" + colors[2].color }}
      >
        Цена: {order.price}
      </p>
    </div>
  );
};
export default CardOrderComponent;
