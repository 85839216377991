// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_result-vertical {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
}
.order_result-vertical > p:first-child {
  margin-top: 20px;
  margin-left: 10px;
}
.order_result-vertical > img {
  width: 100%;
  height: 100px;
}
.order_result-vertical-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 19px;
}
.order_result-vertical-top > div {
  display: flex;
  align-items: center;
}
.order_result-vertical-goods {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
  box-sizing: border-box;
}
.order_result-vertical-buttons {
  display: flex;
  height: -webkit-fill-available;
  align-items: center;
  padding-left: 0px;
  justify-content: center;
  column-gap: 10px;
}
.order_result-vertical-buttons > button {
  border-radius: 3px;
  border: none;
  height: 40px;
  margin: 10px 0px 0px 0px;
  width: 150px;
  box-shadow: -1px 1px 4px #3a3a3a;
}
.order_result-vertical-buttons > .back {
  background-color: transparent;
}
.order_result-vertical-res {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.order_result-vertical > p {
  text-align: start;
  padding-right: 60px;
  padding-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/template-vertical/order-result-component/order-result-component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,YAAA;AACF;AAAE;EACE,gBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,WAAA;EACA,aAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;AAGN;AAAE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AAEJ;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EACA,gBAAA;AAEJ;AADI;EACE,kBAAA;EACA,YAAA;EACA,YAAA;EACA,wBAAA;EACA,YAAA;EACA,gCAAA;AAGN;AADI;EACE,6BAAA;AAGN;AAAE;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;AAEJ;AAAE;EACE,iBAAA;EACA,mBAAA;EACA,kBAAA;AAEJ","sourcesContent":[".order_result-vertical {\n  display: flex;\n  box-sizing: border-box;\n  flex-direction: column;\n  height: 100%;\n  > p:first-child {\n    margin-top: 20px;\n    margin-left: 10px;\n  }\n  > img {\n    width: 100%;\n    height: 100px;\n  }\n  &-top {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0px 19px;\n    > div {\n      display: flex;\n      align-items: center;\n    }\n  }\n  &-goods {\n    display: flex;\n    flex-direction: column;\n    row-gap: 10px;\n    padding: 10px;\n    box-sizing: border-box;\n  }\n  &-buttons {\n    display: flex;\n    height: -webkit-fill-available;\n    align-items: center;\n    padding-left: 0px;\n    justify-content: center;\n    column-gap: 10px;\n    > button {\n      border-radius: 3px;\n      border: none;\n      height: 40px;\n      margin: 10px 0px 0px 0px;\n      width: 150px;\n      box-shadow: -1px 1px 4px #3a3a3a;\n    }\n    >.back {\n      background-color: transparent;\n    }\n  }\n  &-res {\n    display: flex;\n    justify-content: space-between;\n    padding: 0px 20px;\n  }\n  > p {\n    text-align: start;\n    padding-right: 60px;\n    padding-left: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
